
import Pagination from "@/components/shared/Pagination.vue";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import { Component, Prop, Emit, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";

@Component({
  components: {
    Pagination,
    ElementList,
  },
})
export default class Merchants extends Vue {
  @Prop({ required: true }) merchants?: Merchant[];
  @Prop({ required: false }) search?: string;
  @Prop({ required: false, default: null }) title!: string | null;
  newMerchant?: object | null = null;
  currentMerchant?: Merchant | null = null;
  columns: ListColumn<Merchant>[] = [
    {
      header: "Merchant ID",
      key: "merchantId",
      field: "merchantId",
    },
    {
      header: "Name",
      key: "name",
      field: "name",
    },
    {
      header: "Created",
      key: "createdTs",
      field: "createdTs",
    },
  ];
  merchantsPage = 1;
  merchantListLoading = false;
  merchantListErrored = false;

  @Emit()
  editMerchant(merchant: Merchant) {
    window.console.log("editMerchant: " + merchant.merchantId + " on emit");

    //  Emit to parent
    this.$emit("edit-merchant", merchant);
  }

  private filterMerchant(item: Merchant, filter: string) {
    if (filter === "") {
      return item;
    } else {
      return (
        item.merchantId.toLowerCase().includes(filter.toLowerCase()) ||
        item.name.toLowerCase().includes(filter.toLowerCase())
      );
    }
  }
}
