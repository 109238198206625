
import Modal from "@/components/shared/layout/Modal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import {
  SolutionApi,
  SystemApi,
} from "@fundraisingbureauet/admin-typescript-client";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { Solution } from "@fundraisingbureauet/admin-typescript-client/models/Solution";
import { eventHub } from "@/router";
import { SystemType } from "@fundraisingbureauet/admin-typescript-client/models/SystemType";
import { SystemMerchant } from "@fundraisingbureauet/admin-typescript-client/models/SystemMerchant";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";

@Component({ components: { Modal } })
export default class MerchantSolutionEditor extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  @Prop({ required: true })
  solution!: Solution;

  @Prop({ required: false, default: [] })
  systemMerchants!: Array<SystemMerchant>;

  @Prop({ required: false, default: [] })
  gatewayMerchants!: Array<GatewayMerchant>;

  solutionUrl: string | undefined = "";
  editing = false;

  /**
   * Initialise
   */
  created() {
    if (this.solution) {
      this.solutionUrl = this.solution.solutionUrl;
    }
  }

  private startEditing() {
    this.editing = true;
  }

  private stopEditing() {
    this.editing = false;
  }

  /**
   * Initiates chain of creating: DataSet, Communication, PaymentEngine and Exporter
   */
  private unlockSolution() {
    this.createDataSet();
  }

  private createDataSet() {
    new SystemApi(AuthInterceptor.Instance)
      .createSystemMerchant({
        systemType: SystemType.DATASET.toUpperCase() as SystemType, // convert eNum to uppercase string
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: SystemType.DATASET,
        },
      })
      .subscribe(
        () => {
          this.createCommunication();
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private createCommunication() {
    new SystemApi(AuthInterceptor.Instance)
      .createSystemMerchant({
        systemType: SystemType.COMMUNICATION.toUpperCase() as SystemType, // convert eNum to uppercase string
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: SystemType.COMMUNICATION,
        },
      })
      .subscribe(
        () => {
          this.createPaymentEngine();
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private createPaymentEngine() {
    new SystemApi(AuthInterceptor.Instance)
      .createSystemMerchant({
        systemType: SystemType.PAYMENTENGINE.toUpperCase() as SystemType, // convert eNum to uppercase string
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: SystemType.PAYMENTENGINE,
        },
      })
      .subscribe(
        () => {
          this.createExporter();
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private createExporter() {
    new SystemApi(AuthInterceptor.Instance)
      .createSystemMerchant({
        systemType: SystemType.EXPORTER.toUpperCase() as SystemType, // convert eNum to uppercase string
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: SystemType.EXPORTER,
        },
      })
      .subscribe(
        () => {
          //  Toast
          eventHub.$emit("successToast", "Solution unlocked");

          //  Emit to merchant solution
          this.$emit("unlocked-solution");
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private updateSolution() {
    if (this.solution && this.solutionUrl) {
      new SolutionApi(AuthInterceptor.Instance)
        .updateSolution({
          id: this.solution.id,
          solutionUpdate: {
            solutionUrl: this.solutionUrl,
          },
        })
        .subscribe(
          () => {
            //  Toast
            eventHub.$emit("successToast", "Solution updated");
            this.editing = false;
          },
          (error) => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  private visitSolution() {
    if (this.solutionUrl === undefined || this.solutionUrl === "") {
      alert(`Solution URL not defined`);
    } else if (this.solutionUrl && this.solutionUrl.startsWith("http")) {
      window.open(`${this.solutionUrl}`, "_blank");
    } else {
      alert(
        `Solution URL (${this.solutionUrl}) does not seem to be a proper url`
      );
    }
  }
}
