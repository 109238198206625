import { SystemType } from "@fundraisingbureauet/admin-typescript-client/models/SystemType";

export default function systemTypeToUserText(systemType: string): string {
  switch (systemType) {
    case SystemType.COMMUNICATION:
      return "Communication";
    case SystemType.DATASET:
      return "Data-set";
    case SystemType.EVENT:
      return "Event";
    case SystemType.EXPORTER:
      return "Exporter";
    case SystemType.ONBOARDING:
      return "Onboarding";
    case SystemType.PAYMENTENGINE:
      return "Payment engine";
    case SystemType.PAYMENTSESSIONHANDLER:
      return "Payment session handler";
    case SystemType.PHONENUMBERLOOKUP:
      return "Phonenumber lookup";
    case SystemType.SHORTLINKER:
      return "Shortlinker";
    case SystemType.SMSCPH:
      return "SMSCPH";
    case SystemType.SMSINBOUND:
      return "SMS Inbound";
    case SystemType.SMSOUTBOUND:
      return "SMS Outbound";

    default:
      return systemType;
  }
}