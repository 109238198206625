
import MerchantSolutionEditor from "@/components/merchants/solution/MerchantSolutionEditor.vue";
import MerchantSolutionSystems from "@/components/merchants/solution/MerchantSolutionSystems.vue";
import MerchantSolutionGateways from "@/components/merchants/solution/MerchantSolutionGateways.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { SolutionOverview } from "@fundraisingbureauet/admin-typescript-client/models/SolutionOverview";

@Component({
  components: {
    MerchantSolutionEditor,
    MerchantSolutionSystems,
    MerchantSolutionGateways
  }
})
export default class MerchantSolution extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  @Prop({ required: true })
  solutionOverviews!: SolutionOverview;

  private unlockedSolution() {
    //  Emit to merchant modal
    this.$emit("refresh-merchant");
  }

  private updatedSystem() {
    //  Emit to merchant modal
    this.$emit("refresh-merchant");
  }

  private updatedGateway() {
    //  Emit to merchant modal
    this.$emit("refresh-merchant");
  }
}
