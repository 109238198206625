
import { Component, Model, Prop } from "vue-property-decorator";
import Vue from "vue";

@Component({
  components: {},
})
export default class TextInputBlock extends Vue {
  @Model("value")
  value!: string;

  @Prop({ required: true })
  header!: string;

  @Prop()
  helper?: string;

  @Prop()
  required = false;
}
