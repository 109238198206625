
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { Solution } from "@fundraisingbureauet/admin-typescript-client/models/Solution";
import { SystemApi } from "@fundraisingbureauet/admin-typescript-client";
import { SystemType } from "@fundraisingbureauet/admin-typescript-client/models/SystemType";
import { SystemMerchant } from "@fundraisingbureauet/admin-typescript-client/models/SystemMerchant";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  length,
  digits
} from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("length", length);
extend("digits", digits);

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class MerchantSolutionSystemForm extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  @Prop({ required: true })
  solution!: Solution;

  @Prop({ required: true })
  systemMerchant!: SystemMerchant;

  @Prop({ required: true })
  fields!: Array<any>;

  get systemMerchantConfigurationFields(): Array<any> {
    const systemMerchantConfigurationFields = [];

    //  Set configured value
    if (this.systemMerchant.config) {
      for (const field of this.fields) {
        for (const i in this.systemMerchant.config) {
          if (i === field.fieldName) {
            if (this.systemMerchant.config[i] === null) {
              if (field.type === "BOOLEAN") {
                Vue.set(field, "value", field.defaultBoolean);
                systemMerchantConfigurationFields.push(field);
              } else if (field.type === "NUMBER") {
                Vue.set(field, "value", field.defaultNumber);
                systemMerchantConfigurationFields.push(field);
              } else if (field.type === "STRING") {
                Vue.set(field, "value", field.defaultString);
                systemMerchantConfigurationFields.push(field);
              }
            } else {
              Vue.set(field, "value", this.systemMerchant.config[i]);
              systemMerchantConfigurationFields.push(field);
            }
          }
        }
      }
    }

    //  Set default values
    else {
      for (const field of this.fields) {
        if (field.type === "BOOLEAN") {
          Vue.set(field, "value", field.defaultBoolean);
          systemMerchantConfigurationFields.push(field);
        } else if (field.type === "NUMBER") {
          Vue.set(field, "value", field.defaultNumber);
          systemMerchantConfigurationFields.push(field);
        } else if (field.type === "STRING") {
          Vue.set(field, "value", field.defaultString);
          systemMerchantConfigurationFields.push(field);
        }
      }
    }

    return systemMerchantConfigurationFields;
  }

  private createSystem() {
    new SystemApi(AuthInterceptor.Instance)
      .createSystemMerchant({
        systemType: this.systemMerchant.systemType.toUpperCase() as SystemType, // convert eNum to uppercase string
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: this.systemMerchant.systemType,
          config: this.getSystemMerchantConfigPayload()
        }
      })
      .subscribe(
        () => {
          //  Toast
          eventHub.$emit("successToast", "System created");

          //  Emit to merchant solution systems
          this.$emit("created-system");
        },
        error => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private updateSystem() {
    new SystemApi(AuthInterceptor.Instance)
      .updateSystemMerchant({
        systemType: this.systemMerchant.systemType.toUpperCase() as SystemType, // convert eNum to uppercase string
        id: this.merchant.id!,
        systemMerchant: {
          merchantId: this.solution.merchantId,
          merchantName: this.merchant.name, // this.solution.solutionMerchantId,
          solutionType: this.solution.type,
          systemType: this.systemMerchant.systemType,
          config: this.getSystemMerchantConfigPayload()
        }
      })
      .subscribe(
        () => {
          //  Toast
          eventHub.$emit("successToast", "System updated");

          //  Emit to merchant solution systems
          this.$emit("updated-system");
        },
        error => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private getSystemMerchantConfigPayload() {
    const payload = {};

    for (const field of this.systemMerchantConfigurationFields) {
      Vue.set(payload, field.fieldName, field.value);
    }

    return payload;
  }
}
