
import Modal, {
  ModalToolbarAction,
  ModalToolbarSettings
} from "@/components/shared/layout/Modal.vue";
import TextInputBlock from "@/components/shared/inputs/TextInputBlock.vue";
import InfoBox, { InfoElement } from "@/components/shared/layout/InfoBox.vue";
import MerchantHistory from "@/components/merchants/MerchantHistory.vue";
import MerchantSolution from "@/components/merchants/solution/MerchantSolution.vue";
import MerchantModalUpdate from "@/components/merchants/MerchantModalUpdate.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";
import { MerchantApi } from "@fundraisingbureauet/admin-typescript-client";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { MerchantOverview } from "@fundraisingbureauet/admin-typescript-client/models/MerchantOverview";

@Component({
  components: {
    Modal,
    MerchantModalUpdate,
    InfoBox,
    TextInputBlock,
    MerchantHistory,
    MerchantSolution
  }
})
export default class MerchantModal extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  currentMerchant!: Merchant;
  merchantOverview: MerchantOverview | null = null;
  merchantInfoElements: InfoElement[] = [];
  toolbarSettings: ModalToolbarSettings = {
    toolbarTitle: "",
    actions: []
  };
  updated = false;
  editingMerchant = false;

  /**
   * Initialise
   */
  created() {
    this.currentMerchant = this.merchant;
    this.extractMerchantInfo();
    this.getMerchantOverview();
  }

  private extractMerchantInfo() {
    if (this.currentMerchant) {
      this.toolbarSettings.toolbarTitle =
        this.currentMerchant.name +
        (this.currentMerchant.cancellationRequested
          ? " (cancellation requested)"
          : "");
      this.toolbarSettings.actions = this.createToolBarActions();
      this.merchantInfoElements = [
        { header: "Merchant ID", value: this.currentMerchant?.merchantId },
        {
          header: "Customer ID",
          value: this.currentMerchant?.invoiceCustomerId
        },
        {
          header: "Sandbox Subscription",
          value: this.currentMerchant?.invoiceSandboxSubscription ? "Yes" : "No"
        }
      ];
    }
  }

  private createToolBarActions(): ModalToolbarAction[] {
    const toolbarActions: ModalToolbarAction[] = [
      {
        name: "Edit"
      }
    ];

    if (!this.currentMerchant.cancellationRequested) {
      toolbarActions.push({ name: "Request cancellation" });
    }

    //  TODO
    //  toolbarActions.push({ name: "Download config" });

    return toolbarActions;
  }

  private getMerchantOverview() {
    if (this.currentMerchant.id) {
      new MerchantApi(AuthInterceptor.Instance)
        .getMerchant({ id: this.currentMerchant.id })
        .subscribe(
          response => {
            this.merchantOverview = response;

            //  Refresh merchant info
            if (this.merchantOverview.merchant) {
              this.currentMerchant = this.merchantOverview.merchant;
              this.extractMerchantInfo();
            }
          },
          error => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  private toolbarActionClicked(action: string) {
    switch (action) {
      case "Edit":
        this.editMerchant();
        break;
      case "Request cancellation":
        this.requestCancellation();
        break;
      case "Download config":
        this.downloadConfig();
        break;
    }
  }

  private editMerchant() {
    this.editingMerchant = true;
  }

  private stopEditingMerchant() {
    this.editingMerchant = false;
  }

  private merchantUpdated(merchant: Merchant) {
    this.currentMerchant = merchant;
    this.extractMerchantInfo();
    this.stopEditingMerchant();
    this.historyUpdated();
  }

  private historyUpdated() {
    this.updated = true;
    this.getMerchantOverview();
  }

  private requestCancellation() {
    if (this.merchant.id) {
      new MerchantApi(AuthInterceptor.Instance)
        .requestMerchantCancellation({ id: this.merchant.id })
        .subscribe(
          () => {
            this.historyUpdated();
          },
          error => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  private downloadConfig() {
    if (this.currentMerchant.id) {
      console.log("Download config - todo");
    }
  }

  private closeModal() {
    //  Emit to merchant list
    this.$emit("close-modal", this.updated);
  }
}
