
import { Component, Prop, Vue } from "vue-property-decorator";
import { AuthInterceptor } from "@/config";
import { MerchantApi } from "@fundraisingbureauet/admin-typescript-client";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { eventHub } from "@/router";
import { ValidationProvider, ValidationObserver, extend } from "vee-validate";
import {
  required,
  email,
  numeric,
  length,
  digits
} from "vee-validate/dist/rules";

extend("required", required);
extend("email", email);
extend("numeric", numeric);
extend("length", length);
extend("digits", digits);

@Component({
  components: { ValidationProvider, ValidationObserver }
})
export default class MerchantForm extends Vue {
  @Prop({ required: false, default: null })
  merchant!: Merchant;

  name = "";
  address = "";
  postCode = "";
  city = "";
  country = "";
  website = "";
  email = "";
  phone = "";
  merchantId: string | undefined = "";
  invoiceCustomerId: number | undefined = 0; // undefined;
  externalId: number | undefined = 0; // undefined;
  businessCode: string | undefined = "";
  economicId: string | undefined = "";
  smsPaymentAccountNumber: string | undefined = "";
  gdprInformation: string | undefined = "";
  invoiceSandboxSubscription: boolean | undefined;

  /**
   * Initialise
   */
  created() {
    if (this.merchant) {
      this.name = this.merchant.name || "";
      // this.address = this.merchant.address || "";
      // this.postCode = this.merchant.postCode || "";
      // this.city = this.merchant.city || "";
      // this.country = this.merchant.countryCode || "";
      this.website = this.merchant.website || "";
      // this.email = this.merchant.email || "";
      // this.phone = this.merchant.msisdn || "";
      this.merchantId = this.merchant.merchantId;
      // this.businessCode = this.merchant.businessCode || "";
      this.smsPaymentAccountNumber =
        this.merchant.smsPaymentAccountNumber || "";
      this.invoiceCustomerId = this.merchant.invoiceCustomerId;

      this.getIsActive = this.merchant.invoiceSandboxSubscription;
    }
  }

  get getIsActive() {
    return this.invoiceSandboxSubscription;
  }

  set getIsActive(value) {
    this.invoiceSandboxSubscription = value;
  }

  private createMerchant() {
    if (this.merchantId) {
      const payload = this.preparePayload(this.merchantId);

      new MerchantApi(AuthInterceptor.Instance)
        .createMerchant({
          merchant: payload
        })
        .subscribe(
          (merchant: Merchant) => {
            //  Toast
            eventHub.$emit(
              "successToast",
              `Merchant: ${this.name} created with id: ${merchant.id}`
            );

            //  Emit to new merchant modal
            this.$emit("merchant-created", merchant);
          },
          error => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  private updateMerchant() {
    if (this.merchant && this.merchant.id) {
      const payload = this.preparePayload(this.merchant.merchantId);

      new MerchantApi(AuthInterceptor.Instance)
        .updateMerchant({
          id: this.merchant.id,
          merchant: payload
        })
        .subscribe(
          (merchant: Merchant) => {
            //  Toast
            eventHub.$emit(
              "successToast",
              `Merchant '${merchant.name}' updated`
            );

            //  Emit to update merchant modal
            this.$emit("merchant-updated", merchant);
          },
          error => {
            eventHub.$emit("httpError", error);
          }
        );
    }
  }

  private preparePayload(merchantId: string) {
    const payload = {
      merchantId: merchantId
    } as Merchant;

    if (this.name) {
      payload.name = this.name;
    }
    if (this.website) {
      payload.website = this.website;
    }
    if (this.invoiceCustomerId) {
      payload.invoiceCustomerId = this.invoiceCustomerId;
    }
    if (this.smsPaymentAccountNumber) {
      payload.smsPaymentAccountNumber = this.smsPaymentAccountNumber;
    }

    payload.invoiceSandboxSubscription = this.invoiceSandboxSubscription;

    return payload;
  }
}
