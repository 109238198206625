
import Modal from "@/components/shared/layout/Modal.vue";
import MerchantSolutionGatewayModal from "@/components/merchants/solution/MerchantSolutionGatewayModal.vue";
import GatewayModal from "@/components/gateways/GatewayModal.vue";
import MerchantSolutionGatewayEnabler from "@/components/merchants/solution/MerchantSolutionGatewayEnabler.vue";
import gatewayTypeToUserText from "@/components/gateways/GatewayUtil";
import { Component, Prop, Vue } from "vue-property-decorator";
import { GatewayMerchant } from "@fundraisingbureauet/admin-typescript-client/models/GatewayMerchant";
import { GatewayType } from "@fundraisingbureauet/admin-typescript-client/models/GatewayType";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { Solution } from "@fundraisingbureauet/admin-typescript-client/models/Solution";

@Component({
  components: {
    Modal,
    MerchantSolutionGatewayModal,
    GatewayModal,
    MerchantSolutionGatewayEnabler
  }
})
export default class SolutionGateways extends Vue {
  @Prop({ required: true })
  merchant!: Merchant;

  @Prop({ required: true })
  solution!: Solution;

  @Prop({ required: true, default: [] })
  gatewayMerchants!: Array<GatewayMerchant>;

  currentGatewayMerchant: GatewayMerchant | null = null;

  /**
   * Prepare list of solution' gatewayMerchants
   */
  get solutionsGatewayMerchants(): Array<GatewayMerchant> {
    const solutionsGatewayMerchants = [];

    if (this.gatewayMerchants) {
      for (const configurableGateway of this.$root.$data.configurableGateways) {
        //  Flag active system
        let foundGateway = false;

        //  Compare available gateways against active gateway merchants
        for (const gatewayMerchant of this.gatewayMerchants) {
          if (configurableGateway.type === gatewayMerchant.gatewayType) {
            solutionsGatewayMerchants.push(gatewayMerchant);
            foundGateway = true;
          }
        }

        //  Append inactive system
        if (!foundGateway) {
          const nonSystem = {
            gatewayType: configurableGateway.type
          } as GatewayMerchant;
          solutionsGatewayMerchants.push(nonSystem);
        }
      }
    }

    return solutionsGatewayMerchants;
  }

  /**
   * Get gateway's configurable fields
   */
  private getGatewayConfigFields(gatewayMerchant: GatewayMerchant) {
    for (const configurableGateway of this.$root.$data.configurableGateways) {
      if (
        configurableGateway.type === gatewayMerchant.gatewayType &&
        configurableGateway.fields.length > 0
      ) {
        return configurableGateway.fields;
      }
    }
    return [];
  }

  /**
   * Convert to readable friendly text
   */
  private getGatewayType(gatewayType: GatewayType) {
    return gatewayTypeToUserText(gatewayType);
  }

  private createGatewayMerchant(gatewayMerchant: GatewayMerchant) {
    this.currentGatewayMerchant = gatewayMerchant;
  }

  private updateGatewayMerchant(gatewayMerchant: GatewayMerchant) {
    this.currentGatewayMerchant = gatewayMerchant;
  }

  private updatedGateway() {
    this.currentGatewayMerchant = null;

    //  Emit to merchant solution
    this.$emit("updated-gateway");
  }

  private closeModal() {
    this.currentGatewayMerchant = null;
  }
}
