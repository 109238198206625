
import MerchantList from "@/components/merchants/MerchantList.vue";
import MerchantModalRegister from "@/components/merchants/MerchantModalRegister.vue";
import MerchantModal from "@/components/merchants/MerchantModal.vue";
import Pagination from "@/components/shared/Pagination.vue";
import ElementList, {
  ListColumn,
} from "@/components/shared/layout/ElementList.vue";
import { Component, Vue } from "vue-property-decorator";
import { Merchant } from "@fundraisingbureauet/admin-typescript-client/models/Merchant";
import { MerchantsApi } from "@fundraisingbureauet/admin-typescript-client/apis/MerchantsApi";
import { SystemsApi } from "@fundraisingbureauet/admin-typescript-client/apis/SystemsApi";
import { GatewaysApi } from "@fundraisingbureauet/admin-typescript-client/apis/GatewaysApi";
import { AuthInterceptor } from "@/config";
import { eventHub } from "@/router";

@Component({
  components: {
    MerchantList,
    MerchantModalRegister,
    MerchantModal,
    Pagination,
    ElementList,
  },
})
export default class Merchants extends Vue {
  merchants: Merchant[] = [];
  newMerchant?: object | null = null;
  currentMerchant?: Merchant | null = null;
  searchString = "";
  columns: ListColumn<Merchant>[] = [
    {
      header: "Merchant ID",
      key: "merchantId",
      field: "merchantId",
    },
    {
      header: "Name",
      key: "name",
      field: "name",
    },
    {
      header: "Created",
      key: "createdTs",
      field: "createdTs",
    },
  ];
  page = 1;
  listErrored = false;
  listLoading = false;
  listLoaded = false;

  /**
   * Initialise
   */
  created() {
    this.loadSystems();
    this.loadGateways();
    this.loadMerchants();

    //  Initialise modal by query
    window.addEventListener("popstate", () => this.initializeModel(), false);
  }

  private loadSystems() {
    //  Get and store available systems
    new SystemsApi(AuthInterceptor.Instance)
      .listAvailableSystems()
      .subscribe((response) => {
        this.$root.$data.configurableSystems = response;
      });

    //  Get and store configurable systems
    new SystemsApi(AuthInterceptor.Instance)
      .getConfigurableSystems()
      .subscribe((response) => {
        this.$root.$data.systemEndpoints = response;
      });
  }

  private loadGateways() {
    //  Get and store available gateways
    new GatewaysApi(AuthInterceptor.Instance).getAvailableGateways().subscribe(
      (response) => {
        this.$root.$data.availableGateways = response;
      },
      (error) => {
        eventHub.$emit("httpError", error);
      }
    );

    //  Get and store configurable gateways
    new GatewaysApi(AuthInterceptor.Instance)
      .getGatewayConfigurations()
      .subscribe(
        (response) => {
          const gatewayConfigurations = [];

          for (const [key, value] of Object.entries(response)) {
            gatewayConfigurations.push(value);
          }

          this.$root.$data.configurableGateways = gatewayConfigurations;
        },
        (error) => {
          eventHub.$emit("httpError", error);
        }
      );
  }

  private loadMerchants() {
    this.listLoading = true;

    new MerchantsApi(AuthInterceptor.Instance).listMerchants().subscribe(
      (response) => {
        this.listLoaded = true;
        this.merchants = response;
        this.initializeModel();
      },
      (error) => {
        eventHub.$emit("httpError", error);
        this.listErrored = false;
      },
      () => {
        this.listLoading = false;
      }
    );
  }

  private createNewMerchant() {
    this.newMerchant = {};
  }

  private merchantCreated(merchant: Merchant) {
    this.newMerchant = null;
    this.currentMerchant = merchant;
    this.viewEntity(this.currentMerchant.merchantId);

    //  Log
    console.log(`Created merchant: ${this.currentMerchant.merchantId}`);
  }

  private editMerchant(merchant: Merchant) {
    window.console.log(
      "editMerchant: " + merchant.merchantId + " via merchant list"
    );

    this.currentMerchant = merchant;
    this.viewEntity(this.currentMerchant.merchantId);
  }

  private viewEntity(guid: string | undefined) {
    //  Skip double navigation
    if (this.$route.query.merchantId && this.$route.query.merchantId == guid) {
      return false;
    }

    const query: { [key: string]: any } = {};
    query["merchantId"] = guid;

    this.$router.push({
      query: query,
    });
  }

  private initializeModel() {
    const merchantId = this.$route.query.merchantId;
    const search: any = this.$route.query.search;

    //  Query with merchantId
    if (merchantId) {
      //  Log
      console.log(`Query has merchantId: ${merchantId}`);

      //  Get merchant
      const merchant = this.merchants.find(
        (value) => value.merchantId === merchantId
      );
      this.currentMerchant = merchant;

      //  Open modal
      this.viewEntity(merchantId as string);
    }

    //  Query with search
    else if (search) {
      this.searchString = search;
    }
  }

  private closeModal(updated: boolean) {
    //  Reset current
    this.newMerchant = null;
    this.currentMerchant = null;

    this.$router.replace({
      name: "Merchants",
    });

    const query: any = {};

    //  Page number
    query.page = this.page;

    //  Search
    if (this.searchString) {
      query.search = this.searchString;
    }

    if (updated) {
      this.$router.push(
        {
          name: "Merchants",
          query,
        },
        () => {
          this.loadMerchants();
        }
      );
    } else {
      this.$router.replace({
        name: "Merchants",
        query,
      });
    }
  }

  private setPageInUrl(pageNumber: number) {
    const query: any = {};
    this.page = pageNumber;

    //  Query with merchant
    if (this.$route.query.merchantId) {
      //  Skip
    }

    //  Query with search
    else if (this.searchString) {
      query.search = this.searchString;
      query.page = this.page;

      //  Same path
      if (
        query.page === this.$route.query.page &&
        query.search === this.$route.query.search
      ) {
        //  Skip
      } else {
        //  Log
        console.log("Router replace - search");

        this.$router.replace({ query });
      }

      //  Log
      console.log(
        `Page size: ${pageNumber} and ${this.searchString}, set query`
      );
    }

    //  Query with page number
    else {
      query.page = pageNumber;

      //  Same path
      if (query.page === this.$route.query.page) {
        //  Skip
      } else {
        //  Log
        console.log("Router replace - page");

        this.$router.replace({ query });
      }

      //  Log
      console.log(`Page size: ${pageNumber}, set query`);
    }
  }

  private importAll() {
    //  Import all merchants and setup missing solutions
    new MerchantsApi(AuthInterceptor.Instance).importAll().subscribe(
      (response) => {
        console.log(response);
      },
      (error) => {
        console.log(error);
      }
    );
  }
}
